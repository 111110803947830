var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('h2',{staticClass:"h2Style"},[_c('img',{staticClass:"oee",staticStyle:{"vertical-align":"middle"},attrs:{"src":require("../assets/wrenchBlack.svg")}}),_vm._v(" Executive Permissions ")]),_c('div',[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headersData,"items":_vm.operatorsData,"search":_vm.search,"items-per-page":15},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-switch',{attrs:{"x-small":""},on:{"click":function($event){return _vm.changeUserStatus(item.isActive, item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.editDoc(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.addDoc()}}},'v-btn',attrs,false),on),[_vm._v(" Add ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_c('h4',{staticClass:"h2Style"},[_vm._v("Add Executive")])])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Password","type":'password'},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('h4',[_vm._v("Is Active")]),_c('v-radio-group',{attrs:{"column":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-radio',{attrs:{"label":"True","value":true}}),_c('v-radio',{attrs:{"label":"False","value":false}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Submit ")])],1)],1)],1)],1)]},proxy:true}])})],1),_c('div',{attrs:{"id":"sucessAlert"}},[_c('v-alert',{attrs:{"outlined":"","type":"success","text":""}},[_vm._v(" Data Saved successfully. ")])],1),_c('div',{attrs:{"id":"errorAlert"}},[_c('v-alert',{attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" Error while saving the data. ")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }