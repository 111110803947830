<template>
  <v-container>
    <div>
      <h2 class="h2Style">
        <img
          class="oee"
          style="vertical-align: middle"
          src="../assets/wrenchBlack.svg"
        />
        Executive Permissions
      </h2>
      <div>
        <v-card>
          <v-data-table
            :headers="headersData"
            :items="operatorsData"
            :search="search"
            :items-per-page="15"
          >
          <template v-slot:item.isActive="{ item }">
            <v-container
              class="px-0"
              fluid
            >
              <v-switch
                v-model="item.isActive"
                x-small
                @click="changeUserStatus(item.isActive, item)"
              ></v-switch>
            </v-container>
          </template>
            <!-- <template v-slot:item.status="{ item }">
              <v-icon
                x-large
                @click="changeStatus(item, false)"
                color="green"
                v-if="item.status === true"
              >
                mdi-toggle-switch
              </v-icon>
              <v-icon
                x-large
                @click="changeStatus(item, true)"
                color="red"
                v-if="item.status === false"
              >
                mdi-toggle-switch-off
              </v-icon>
            </template> -->
            <template v-slot:item.actions="{ item }">
              <v-icon medium @click="editDoc(item)"> mdi-pencil </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="1000px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      @click="addDoc()"
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Add
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline"
                        ><h4 class="h2Style">Add Executive</h4>
                      </span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="name"
                              label="Name"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="email"
                              label="Email"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="password"
                              label="Password"
                              :type="'password'"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <h4>Is Active</h4>
                        <v-radio-group v-model="active" column >
                          <v-radio label="True" v-bind:value="true" ></v-radio>
                          <v-radio label="False" v-bind:value="false" ></v-radio>
                        </v-radio-group>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save">
                        Submit
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>

        <div id="sucessAlert">
          <v-alert outlined type="success" text>
            Data Saved successfully.
          </v-alert>
        </div>
        <div id="errorAlert">
          <v-alert outlined type="warning" prominent border="left">
            Error while saving the data.
          </v-alert>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import constants from "../constants";
// import { config } from 'vue/types/umd';
// const organizationId = parseInt(sessionStorage.getItem("organizationId"));
export default {
  name: "Executives",
  data: function () {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      name: "",
      email: "",
      active: "-",
      password: "-",
      role: "-",
      headersData : [
        {
          text: "Name",
          value: "name",
          class: "indigo--text subtitle-1",
        },
        {
          text: "Email",
          value: "email",
          class: "indigo--text subtitle-1",
        },
        
        {
          text: "Status",
          value: "isActive",
          class: "indigo--text subtitle-1",
        },
        
      ],
      operatorsData: []
    };
    
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.setGridData();
    this.setMachineData();
    this.selectProps = {
      // parameter_id: ["Nozzle Temperature", "Z1 Temperature"],
      enable: ["Y", "N"],
    };
  },
  methods: {
    async changeUserStatus(status, item){
      var config = {
          method: "post",
          url: `${constants.backend_url}/updateExecutive`,
          headers: {
            "Content-Type": "application/json",
            token: `"${sessionStorage.getItem('jwt')}"`,
            
          },
          data: {
            "id": item._id,
            "isActive": status
          }
        };
       console.log(item._id, status)
        const res = await axios(config);
        console.log(res)
    },
    setMachineData() {
      axios
        .get(`${constants.backend_url}/`)

        .then((response) => {
          console.log("response--getMachineNames", response.data);
          this.machineItems = response.data.data;
        })
        .catch((error) => {
          console.log("error--getMachineNames", error.message);
        });

      // this.selectProps = ["Nozzle Temperature","Z1 Temperature"]
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    assignparam() {
      // let machineID = event;

      axios
        .get(`${constants.backend_url}/`)
        .then((response) => {
          // console.log("response--parameter names", response.data.data);
          this.parameters = [];
          this.parameters = response.data.data;
        })
        .catch((error) => {
          console.log("error--parameter names", error.message);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      // if (!this.editedItem.machine_id) {
      //   alert("Please select Machine ");
      //   return;
      // } else if (!this.editedItem.parameter_id) {
      //   alert("Please select Operational Parameter");
      //   return;
      // } else if (!this.editedItem.threshold_value) {
      //   alert("Please Enter Threshold");
      //   return;
      // } else if (!this.editedItem.email) {
      //   alert("Please Enter eMail ");
      //   return;
      // } else if (!this.editedItem.phone_number) {
      //   alert("Please Enter Phone Number ");
      //   return;
      // } else if (!this.editedItem.enable_flag) {
      //   alert("Please select Enable");
      //   return;
      // }
      console.log("name->", this.name);
      console.log("email->",this.email);
      console.log("password->",this.password);
      console.log("isActive",this.active);
      let config={
        name:this.name,
        email:this.email,
        isActive:this.active,
        password:this.password,
        role:"executive",
      }
      console.log(sessionStorage.getItem('jwt'))
      axios({
        url:`${constants.backend_url}/createExecutive`,
        method: "POST",
        headers:{
          token:`"${sessionStorage.getItem('jwt')}"`
        },
        data:config
      }).then((response) => {
          console.log(response);
          alert("Executive created successfully")
        })
        .catch((error) => {
          console.log("error", error.message);
          document.querySelector("#errorAlert").style.display = "block";
        });
      this.close();
    },

    setGridData: function () {
      let config = {
        method:"get",
        url: `${constants.backend_url}/executives`,
        headers:{token:`"${sessionStorage.getItem('jwt')}"`}
      }

      axios(config)
        .then((response) => {
          console.log(response.data.data);
          this.operatorsData = response.data.data;
        })
        .catch((error) => {
          console.log("error", error.message);
        });
    },

    addDoc() {
      this.isEdit = false;
    },

    editDoc(item) {
      this.assignparam(item.machine_id);
      this.isEdit = true;
      this.editedItem = item;
      this.dialog = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.h2Style {
  color: #000000;
  padding: 12px;
}
#sucessAlert {
  display: none;
}
#errorAlert {
  display: none;
}
.gridHeader {
  color: #0a47ba;
  font: bold;
}
</style>
